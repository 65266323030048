import React, { lazy, Suspense } from "react";

const Menu = lazy(() => import("../pages/menu"));
const Franchise = lazy(() => import("../pages/franchise"));
const Login = lazy(()=>import( "../../public/login"));
const Page404 = lazy(() => import("../pages/page404"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const Elements = lazy(() => import("../pages/settings/elements"));
// const Landing = lazy(() => import("../pages/landing/landing"));
const Result = lazy(() => import("../pages/result/index"));
const Exam = lazy(() => import("../pages/exam/index"));
const Question = lazy(() => import("../pages/question/index"));
const Subject = lazy(() => import("../pages/subject"));
const Topic = lazy(() => import("../pages/topic"));
const Notification = lazy(() => import("../pages/notification"));
const Banner = lazy(() => import("../pages/banner"));
const Note = lazy(() => import("../pages/note"));
const ExamAnswer = lazy(() => import("../pages/examAnswer"));
const ExamResult = lazy(() => import("../pages/examResult/index.js"));
const Student = lazy(() => import("../pages/student/index.js"));
const ExamQuestion = lazy(() => import("../pages/examQuestion/index.js"));

//NEW CUSTOM PAGES
const StudentDashboard = lazy(() =>
  import("../pages/studentPages/studentDashboard/Landing")
);
const Stream = lazy(() => import("../pages/stream"));
const ExploreTopic = lazy(() =>
  import("../pages/studentPages/exploreTopic/index.js")
);
const ExamList = lazy(() => import("../pages/studentPages/examList/index.js"));
const Credential = lazy(() => import("../pages/credential/index.js"));
const PlanPurchase = lazy(() => import("../pages/planPurchase/index.js"));

const RenderPage = (page, key, user, privileges) => {
  const renderComponent = (Component) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component key={key} {...privileges} />
    </Suspense>
  );

  switch (page) {
    case "login":
      return renderComponent(Login);
    case "menu":
      return renderComponent(Menu);
    case "franchise":
      return renderComponent(Franchise);
    case "dashboard":
      return renderComponent(Dashboard);
    case "elements":
      return renderComponent(Elements);
    case "result":
      return renderComponent(Result);
    case "exam":
      return renderComponent(Exam);
    case "question":
      return renderComponent(Question);
    case "topic":
      return renderComponent(Topic);
    case "notification":
      return renderComponent(Notification);
    case "banner":
      return renderComponent(Banner);
    case "note":
      return renderComponent(Note);
    case "exam-answer":
      return renderComponent(ExamAnswer);
    case "exam-question":
      return renderComponent(ExamQuestion);
    case "exam-result":
      return renderComponent(ExamResult);
    case "student":
      return renderComponent(Student);

    //CUSTOM DESIGN
    case "student-dashboard":
      return renderComponent(StudentDashboard);
    case "stream":
      return renderComponent(Stream);
    case "subject":
      return renderComponent(Subject);
    case "explore-topic":
      return renderComponent(ExploreTopic);
    case "examList-student":
      return renderComponent(ExamList);
    case "credential":
      return renderComponent(Credential);
    case "plan-purchase":
      return renderComponent(PlanPurchase);

    default:
      return renderComponent(Page404);
  }
};

export default RenderPage;
