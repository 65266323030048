// import { Route } from "react-router-dom";
// import EventsLanding from "../pages/landing/eventsLanding";
// import EventDetailLanding from "../pages/landing/eventDetailLanding";
// import Idcard from "../pages/landing/idcard";
// import React from "react";

const CustomPublicRoute = () => [
  // <Route key="events" path="/events" element={<EventsLanding></EventsLanding>} />,
  // <Route key="event-details" path="/events/:slug" element={<EventDetailLanding />} />,
  // <Route key="id-card" path="/my-id-card/:event/:slug" element={<Idcard />} />, // Add the custom redirect route here
];
export default CustomPublicRoute;
