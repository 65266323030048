import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import { appTheme } from "../../project/brand/project";

export const CountryCode = styled.div`
  display: flex;
  position: absolute;
  left: 4px;
  top: 30px;
  border-radius: 0;
  border-right: 1px solid lightgrey;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  .options {
    position: absolute;
    top: 33px;
    z-index: 1001;
    background: white;
    border: 1px solid #e2e4e9;
    transition: all 0.2s ease 0s;
    padding: 8px;
    width: max-content;
    text-align: justify;
    gap: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 11px;
    left: 0;
    .option {
    }
    .line {
      border-bottom: 1px solid lightgray;
    }
  }
`;
export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex: calc(50% - 10px);
  animation: ${(props) => props.animation};
  flex-direction: column;
  gap: 4px;
  max-width: 370px;
  &.checkbox {
    width: 100%;
    flex: none;
    height: 40px;
    -webkit-box-align: left;
    align-items: left;
    display: flex;
    flex-direction: row;
    max-width: 350px;
    flex-direction: column;
    gap: 10px;
  }
  &.short {
    width: 30px;
    min-width: auto;
  }
  &.disabled {
    display: none;
  }
  &.textarea {
    flex: calc(100% - 10px);
    max-width: 720px;
  }
  &.direct input {
    /* background-color:transparent; */
    margin-bottom: 0;
    border-radius: 0;
  }
  &.small {
    width: 25%;
    flex: 1 1 calc(25% - 10px);
  }
  &.full {
    flex: 1 1 calc(100%);
  }
  && {
    /* Styles to apply when parent has class shrink */
    .popup & {
      &:nth-of-type(even) {
        margin-left: 5px;
      }
      &:nth-of-type(odd) {
        margin-right: 5px;
      }
      &.textarea {
        flex: calc(100% - 0px);
        margin-right: 0px;
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  > svg {
    position: absolute;
    top: 36px;
    left: 10px;
  }
  @media (max-width: 768px) {
    flex: 100%;
    margin: 0px !important;
    &.checkbox {
      flex: 1 1 100%;
      margin: 0px 5px 15px !important;
    }
  }
  ${(props) =>
    props.children &&
    css`
      & input:focus ~ .floating-label,
      textarea:focus ~ .floating-label,
      textarea:not(:focus):valid ~ .floating-label,
      input:not(:focus):valid ~ .floating-label,
      input[type="date"]:not(:focus):invalid ~ .floating-label,
      .filter input[type="date"]:not(:focus):invalid ~ .floating-label,
      input[type="datetime-local"]:not(:focus):invalid ~ .floating-label,
      .filter
        input[type="datetime-local"]:not(:focus):invalid
        ~ .floating-label {
        top: -1px;
        left: 13px;
        right: 6px;
        font-size: 11px;
        opacity: 1;
        text-align: left;
        height: 19px;
        padding: 3px 0;
      }
    `}
`;
export const FileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0px;
  color: ${appTheme.text.soft};
  background: ${appTheme.bg.white};
  font-weight: 400;
  gap: 20px;
  padding: 0px;
  > div {
    display: flex;
    gap: 20px;
  }
  > div > div:first-child {
    img {
      width: 65px;
      object-fit: cover;
      height: 65px;
      border-radius: 50%;
    }
  }
  > div > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &.disabled {
    display: none;
  }
`;
export const Label = styled.label`
  pointer-events: none;
  font-size: 10px;
  transition: all 0.1s ease;
  color: ${appTheme.text.main};
  background-color: transparent;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  letter-spacing: -0.006em;
  gap: 5px;
  pointer-events: none;
  &.disabled {
    display: none;
  }
  &.phone2 {
    left: 70px;
  }
  &.phone3 {
    left: 75px;
  }
  .sublabel {
    font-weight: 400;
    color: ${appTheme.text.sub};
    margin-left: 5px;
  }
  i {
    font-weight: 400;
    color: ${appTheme.primary.base};
    margin-left: 5px;
  }
  /* &.shrink {
    display: none;
    color: black;
    &.phone2 {
      left: 20px;
    }
    &.phone3 {
      left: 25px;
    }
  } */
  /* &.error {
    color: red;
    color: red;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 93%;
  } */
  &.checkbox {
    position: initial;
    pointer-events: initial;
    display: flex;
    align-items: center;
  }
  && {
    /* Styles to apply when parent has class shrink */
    .filter & {
      top: 10px;
      font-size: 10px;
    }
  }
`;

export const CheckBox = styled.input`
  margin: 0;
  margin-right: 5px;
  & ~ .checkmark {
    background-color: #ccc;
  }

  &:checked ~ .checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  &:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
export const Input = styled.input`
  box-sizing: border-box;
  padding: 10px 10px 10px 12px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${appTheme.stroke.soft};
  height: 40px;
  font-weight: 400;
  transition: all 0.2s ease-out 0s;
  color: ${appTheme.text.soft};
  background: ${appTheme.bg.white};
  &.phone2 {
    padding-left: 65px;
    color: black;
  }
  &.phone3 {
    padding-left: 70px;
    color: black;
  }
  &.shrink {
    /* padding: 1.2em 13px 0; */
    color: ${appTheme.text.main};
    &.phone2 {
      padding-left: 65px;
    }
    &.phone3 {
      padding-left: 70px;
    }
  }
  &.has-icon {
    padding-left: 30px;
  }
  &.buttonText {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }
  appearance: none;
  &:disabled {
    background: ${appTheme.bg.weak};
    color: ${appTheme.text.sub};
    cursor: not-allowed;
    border: 0;
  }
  &:focus {
    border: 1px solid ${appTheme.stroke.strong};
    box-shadow: 0px 0px 0px 4px #e4e5e7;
  }
`;
export const DatetimeInput = styled(DatePicker)`
  box-sizing: border-box;
  padding: 10px 10px 10px 12px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${appTheme.stroke.soft};
  height: 40px;
  font-weight: 400;
  transition: all 0.2s ease-out 0s;
  color: ${appTheme.text.soft};
  background: ${appTheme.bg.white};
  &.shrink {
    /* padding: 1.2em 13px 0px; */
    color: black;
  }
  &:disabled {
    background: ${appTheme.bg.weak};
    color: ${appTheme.text.sub};
    cursor: not-allowed;
    border: 0;
  }
  && {
    /* Styles to apply when parent has class shrink */
    .filter & {
      padding: 1.4em 13px 0px;
      margin: 4px 0px 0px 0;
      background-color: white;
      height: 40px;
    }
  }
`;
export const DatetimeInputDirectOrder = styled(DatePicker)`
  box-sizing: border-box;
  padding: 10px 10px 10px 12px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${appTheme.stroke.soft};
  height: 40px;
  font-weight: 400;
  transition: all 0.2s ease-out 0s;
  color: ${appTheme.text.soft};
  background: ${appTheme.bg.white};
  &.shrink {
    padding: 1.6em 13px 0;
    color: black;
  }
`;
export const Button = styled.button`
  height: 40px;
  border-radius: 8px;
  background: ${appTheme.bg.surface};
  color: ${appTheme.text.white};
  font-size: 14px;
  font-weight: 500;
  padding: 0px 4px 0px 4px;
  gap: 8px;
  opacity: 0px;
  &.red {
    background: rgb(229, 49, 33) !important;
    color: white !important;
  }
  border: 1px solid #e2e4e9;
  transition: all 0.5s ease;
  margin-top: 10px;
  max-width: 370px;
  padding: 10px 15px;
  width: auto;
  border-color: ${(props) => props.theme.border};
  cursor: pointer;
  border-width: 0;
  justify-content: center;
  &.close {
    background: ${appTheme.bg.white};
    color: ${appTheme.text.sub};
    border: 1px solid ${appTheme.stroke.soft};
  }
  &.widges {
    background: ${appTheme.bg.plain};
    color: ${appTheme.text.sub};
    border: 1px solid ${appTheme.stroke.soft};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px;
    width: 90px;
    height: 70px;
    transition: background 0.3s, color 0.3s, border-color 0.3s;

    &:hover {
      background: ${appTheme.bg.white};
      color: ${appTheme.text.main};
      border-color: ${appTheme.stroke.main};
    }
  }
  &.custom {
    margin: 0;
    gap: 5px;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    width: fit-content;
  }
  &.left {
    margin: 0;
    margin-right: auto;
  }
  &.right {
    margin: 0;
    margin-left: auto;
  }
  &.center {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &.secondary {
    background: ${appTheme.bg.white};
    color: ${appTheme.text.sub};
    border: 1px solid ${appTheme.stroke.soft};
  }
  &.margin-top {
    margin-top: 10px;
  }
  &.full-width,
  &.embed {
    width: 100%;
    background: ${appTheme.primary.base};
    color: ${appTheme.text.white};
  }
  &:disabled {
    background-color: ${(props) => props.theme.disabledBackground};
    color: ${(props) => props.theme.disabledForeground};
    cursor: not-allowed;
  }
  &.buttonText {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid ${appTheme.stroke.soft};
  }
  &.buttonText.active {
    background-color: transparent;
    color: #df1c41;
  }
  &.linkbutton {
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: right;
    color: ${appTheme.text.main};
    border-bottom: 1px solid;
    height: 30px;
    padding: 0;
    border-radius: 0;
    margin-top: 0;
  }
  &.no-line {
    border: none;
  }
`;
export const InputBox = styled.div`
  display: flex;
`;
export const TextArea = styled.textarea`
  box-sizing: border-box;
  padding: 10px 10px 10px 12px;
  outline: none !important;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${appTheme.stroke.soft};
  font-weight: 400;
  transition: all 0.2s ease-out 0s;
  color: ${appTheme.text.soft};
  background: ${appTheme.bg.white};
  resize: none;
  &:focus {
    border: 1px solid ${appTheme.stroke.strong};
    box-shadow: 0px 0px 0px 4px #e4e5e7;
  }
  &.medium {
    height: 150px;
  }
  &.large {
    height: 230px;
  }
  &.shrink {
    /* padding: 1.5em 13px 0px; */
    color: black;
  }
  &:disabled {
    background: ${appTheme.bg.weak};
    color: ${appTheme.text.sub};
    cursor: not-allowed;
    border: 0;
  }
`;
export const Info = styled.div`
  padding-left: 0px;
  font-size: 14px;
  width: 100%;
  margin: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
  &.disabled {
    display: none;
  }
  &.title {
    font-size: 16px;
    padding: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
  }
  svg {
    width: 14px;
    margin-right: 5px;
    margin-top: 2px;
    align-items: baseline;
    display: flex;
    align-self: baseline;
  }
`;
export const Line = styled.div`
  width: 100%;
  height: 1px;
  gap: 0px;
  opacity: 0px;
  background: ${appTheme.stroke.soft};
`;
export const SubHead = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  width: 100%;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-self: flex-start;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
  font-size: 18px;
  text-align: left;
  &.margin {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  > div {
    gap: 10px;
    display: flex;
    min-height: 24px;
    justify-content: left;
  }
  &.line::after {
    content: "";
    height: 1px;
    font-size: 20px;
    background: ${appTheme.stroke.soft};
    opacity: 0.6;
    width: 100%;
  }
  &.custom {
    margin: 0px 0px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left !important;
    color: ${appTheme.text.sub};
    margin: 0;
  }
`;
export const CloseButton = styled.span`
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 0px;
  left: auto;
  z-index: 1;
  color: #868686;
  cursor: pointer;
  &.info-select {
    right: 45px;
  }
`;
export const InfoBox = styled.div`
  position: absolute;
  right: 0px;
  top: 20px;
  left: auto;
  z-index: 10;
  cursor: pointer;
  background: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  max-width: 100%;
  border: 1px solid #e2e4e9;
  text-align: left;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 12px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  bold {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
`;
